import {useSelector} from "react-redux";
import _ from "lodash";

export function useGetBranchID() {
  let {branchId: branchID, role} = useSelector((state) => state.user.currentUser.employee);
  let localStorage = sessionStorage.getItem("selectedBranchID");

  return localStorage ? localStorage : role.name === "Admin" ? null : branchID;
}

export function useGetUserData() {
  let {accessMenus, currentUser} = useSelector((state) => state.user);
  let {role, employee} = currentUser;

  let branchID = employee?.branchId;
  let localStorage = sessionStorage.getItem("selectedBranchID");
  let selectedBranchGroupIdLS = sessionStorage.getItem("selectedBranchGroupId");
  let isAdmin = role.name === "Admin";
  let isHoa = role.name === "Head of Area";
  let isSA = role.name === "Sales Aqusition";
  let isSC = role.name === "Sales Coordinator";
  let isBossStockist = role.name === "Bos Stokis";
  let isNavBarShow = !!_.find(accessMenus, (item) => item.menu === "navbar.branch");
  let isNavBranchGroupShow =
    !!_.find(accessMenus, (item) => item.menu === "navbar.branchgroup") && !isNavBarShow;

  return {
    accessMenus: employee?.role?.accessMenus ?? [],
    branchId: localStorage ? localStorage : isNavBarShow ? null : branchID,
    selectedBranchGroupId: selectedBranchGroupIdLS ? selectedBranchGroupIdLS : null,
    role,
    employee,
    employeeId: employee.id,
    companyId: role.companyId,
    isAdmin,
    isHoa,
    isSA,
    isSC,
    isBossStockist,
    isNavBarShow,
    isNavBranchGroupShow,
  };
}

export function useGetCompanyID() {
  const companyId = useSelector((state) => state.user.currentUser.employee.role.companyId);
  return companyId || null;
}

export function useGetEmployeeID() {
  const employeeId = useSelector((state) => state.user.currentUser.employee.id);
  return employeeId || null;
}
